import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/commercetools/components/providers/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/cart/apply-discount/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/cart/remove-discount/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/cart/templates/item/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/addresses/addresses.form.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/addresses/edit-address-button.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/payment/_components/add-payment-method.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/payment/_components/confirm-review-button.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/payment/_components/edit-payment-button.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/payment/_components/payment-details.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/payment/payment-wrapper/stripe-wrapper.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/review/review.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/shipping/edit-shipping-button.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/checkout/shipping/select-shipping-method.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/product/detail/add-to-cart-button.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/product/detail/description.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/product/filters/filter-component.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/product/selectors/color.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/product/selectors/size.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/thank-you/thank-you-detail.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/ui/components/image/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/ui/components/label.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/components/ui/components/progress-bar.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/account/addresses/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/account/change-password/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/account/details/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/account/login/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/account/orders/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/content/spacer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/global-messaging-banner/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-background/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-categories/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-eyebrow-title/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-products/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-single-image/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-single-panel/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/hero-with-video-background/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/newsletter-form/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/hero/page-with-title-markdown/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/products/carousel/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/products/details/carousel/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/products/list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/products/search-list/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/structure/footer/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/poc/frontend/src/tastics/structure/header/index.tsx")