"use client";

import { CardElement } from "@stripe/react-stripe-js";

import { StripeCardElementOptions } from "@stripe/stripe-js";
import { Typography } from "@/components/ui/components/typography";
import { useMemo } from "react";

export const StripePaymentCard = ({
	onCompleted,
}: {
	onCompleted: (complete: boolean) => void;
}) => {
	const useOptions: StripeCardElementOptions = useMemo(() => {
		return {
			style: {
				base: {
					fontFamily: "Inter, sans-serif",
					color: "#424270",
					"::placeholder": {
						color: "rgb(107 114 128)",
					},
				},
			},
			classes: {
				base: "pt-3 pb-1 block w-full md:w-2/4 h-11 px-4 mt-0 bg-ui-bg-field border rounded-md appearance-none focus:outline-none focus:ring-0 focus:shadow-borders-interactive-with-active border-ui-border-base hover:bg-ui-bg-field-hover transition-all duration-300 ease-in-out",
			},
		};
	}, []);

	return (
		<div className="asio-payment-card-container">
			<Typography.Text className="txt-medium-plus text-ui-fg-base mb-1">
				Enter your card details:
			</Typography.Text>

			<CardElement
				options={useOptions as StripeCardElementOptions}
				onChange={(e) => {
					onCompleted(e.complete);
				}}
			/>
		</div>
	);
};
